import { gql, useMutation } from '@apollo/client'
import { Box, Button, Flex } from '@chakra-ui/react'
import { useFormik } from 'formik'
import moment from 'moment/moment'

import React from 'react'

import DatePickerInput from '~/components/DatePicker'
import { onError } from '~/components/helpers'
import {
	LeadsExtractsAnalytics,
	LeadsExtractsAnalyticsVariables,
} from '~/components/reports/__generated__/LeadsExtractsAnalytics'
import useToast from '~/components/ui/Toast'

export const leadsExtractsAnalyticsMutation = gql`
	mutation LeadsExtractsAnalytics($type: String!, $date: String!) {
		leadsExtractsAnalytics(type: $type, date: $date) {
			csv
		}
	}
`

const LeadExtractsTab = () => {
	const toast = useToast()

	const [extractLead] = useMutation<LeadsExtractsAnalytics, LeadsExtractsAnalyticsVariables>(
		leadsExtractsAnalyticsMutation,
		{
			onError: error => onError(error, toast),
		}
	)

	const formik = useFormik({
		initialValues: {
			date: new Date().toDateString(),
			type: 'CST',
		},
		validateOnChange: true,
		onSubmit: async () =>
			await extractLead({
				variables: { date: moment(formik.values.date).format('YYYY-MM'), type: 'CST' },
			}),
	})

	const handleDownload = async (type: 'CST' | 'TCO') => {
		const result = await extractLead({
			variables: { date: moment(formik.values.date).format('YYYY-MM'), type: type },
		})
		const csvContent = result?.data?.leadsExtractsAnalytics?.csv
		if (csvContent) {
			const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
			const url = URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `leads_extract_${type}_${formik.values.date}.csv`)
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}

	return (
		<Box>
			<form onSubmit={formik.handleSubmit}>
				<Flex
					w='full'
					flexWrap='wrap'
					columnGap='0.4em'
					p={2}
					backgroundColor='#e4e2e2'
					zIndex={20}
				>
					<Flex
						align='center'
						sx={{
							'.chakra-input': {
								background: '#fff !important',
								color: '#000 !important',
							},
							'.react-datepicker__month-text--selected': {
								color: '#000 !important',
								background: '#eee',
								borderRadius: '2px',
							},
							'.react-datepicker__month-text:hover': {
								color: '#515151 !important',
							},
							'> div': {
								zIndex: 20,
							},
						}}
					>
						<DatePickerInput
							name='date'
							dateFormat='MMM yyyy'
							showMonthYearPicker
							showFullMonthYearPicker
							defaultValue={formik.values.date}
							required={true}
							onChange={value => formik.setFieldValue('date', value)}
						/>
					</Flex>
				</Flex>
			</form>
			<Flex direction='row' background='white' p='1em' mb='1em' gap={3}>
				<Button onClick={() => handleDownload('CST')}>Download CST report</Button>
				<Button onClick={() => handleDownload('TCO')}>Download TCO report</Button>
			</Flex>
		</Box>
	)
}

export default LeadExtractsTab
