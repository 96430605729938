import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

import React, { FC, useState } from 'react'

import LeadAnalyticsTab from '~/components/reports/LeadAnalyticsTab'
import LeadConversionAnalyticsTab from '~/components/reports/LeadConversionAnalyticsTab'
import LeadExtractsTab from '~/components/reports/LeadExtractsTab'

const Reports: FC = () => {
	const [tabIndex, setTabIndex] = useState<number>(0)
	console.log(tabIndex)

	const getTabsConfig = (): { id: string; title: string; component: React.ReactNode }[] => {
		return [
			{
				id: 'leadsAnalyticsTab',
				title: 'Leads Analytics',
				component: <LeadAnalyticsTab />,
			},
			{
				id: 'leadsConversionAnalyticsTab',
				title: 'Leads Conversion',
				component: <LeadConversionAnalyticsTab />,
			},
			{
				id: 'leadsExtractsTab',
				title: 'Leads Extracts',
				component: <LeadExtractsTab />,
			},
		]
	}

	const tabsConfig = getTabsConfig()

	return (
		<Box>
			<Flex bg='#F8F8F8' width='100%' overflowY='scroll'>
				<Tabs
					variant='soft-rounded'
					colorScheme='transparent'
					width='100%'
					onChange={index => setTabIndex(index)}
				>
					<TabList p={4}>
						{tabsConfig.map(item => {
							return (
								<Tab
									_selected={{ color: '#EEAB7E', border: '1px solid #EEAB7E' }}
									color='#191C1A'
									key={item.id}
									mr={8}
									fontSize='1em'
									textTransform='uppercase'
								>
									{item.title}
								</Tab>
							)
						})}
					</TabList>
					<TabPanels>
						{tabsConfig.map(item => {
							return (
								<TabPanel key={item.id + 'content'} padding='0'>
									{item.component}
								</TabPanel>
							)
						})}
					</TabPanels>
				</Tabs>
			</Flex>
		</Box>
	)
}

export default Reports
